var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Date ")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                dateFormat: 'd/m/Y',
                defaultDate: new Date(),
                maxDate: new Date(),

              }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Branch")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.branchOption},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Narration")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Narration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Narration"},model:{value:(_vm.narration),callback:function ($$v) {_vm.narration=$$v},expression:"narration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Journal', 'Credit Note', 'Debit Note']},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Total Credit")]),_c('p',{class:_vm.total_credit != _vm.total_debit ? 'text-danger' : ''},[_c('b',[_vm._v(_vm._s(_vm.total_credit))])])])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Total Debit")]),_c('p',{class:_vm.total_credit != _vm.total_debit ? 'text-danger' : ''},[_c('b',[_vm._v(_vm._s(_vm.total_debit))])])])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Voucher Info","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticStyle:{"width":"100%","overflow":"auto"}},[_c('table',{staticClass:"table table-bordered",staticStyle:{"width":"28.9cm"}},[_c('thead',{staticClass:"text"},[_c('tr',[_c('th',{staticStyle:{"width":"1cm"}},[_vm._v("ID")]),_c('th',{staticStyle:{"width":"5cm"}},[_vm._v(" Dr./Cr."),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]),_c('th',{staticStyle:{"width":"5cm"}},[_vm._v(" ledger"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]),_c('th',{staticStyle:{"width":"6cm"}},[_vm._v("Amount")]),_c('th',{staticStyle:{"width":"1cm"}},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.voucherinfo),function(item,id){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(id + 1))]),_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"This"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Debit', 'Credit']},model:{value:(item.drcr),callback:function ($$v) {_vm.$set(item, "drcr", $$v)},expression:"item.drcr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"ledger"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ledgerOption},model:{value:(item.ledger),callback:function ($$v) {_vm.$set(item, "ledger", $$v)},expression:"item.ledger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"None"},on:{"input":_vm.handleTotalCrDr},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1),_c('td',[_c('b-button',{staticClass:"mt-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeRow(id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)])}),0)])]),_c('b-button',{staticClass:"mb-1 mt-1",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add More")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.submitDisable},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }